import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {HttpClientModule} from '@angular/common/http';
import {CheckboxComponent} from './app/_widgets/checkbox/checkbox.component';
import {ToastComponent} from './app/_widgets/toast/toast.component';
import {ToastManagerComponent} from './app/_widgets/toast/toast-manager.component';
import {CurrencyPipe} from '../pipes/currency.pipe';
import {DatetimePipe} from '../pipes/datetime.pipe';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        TranslateModule,
    ],
    declarations: [
        CheckboxComponent,
        ToastComponent,
        ToastManagerComponent,


        // Directives

        // Pipes
        CurrencyPipe,
        DatetimePipe,
    ],
    exports: [
        // Modules
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        HttpClientModule,
        FormsModule,
        TranslateModule,

        // Components
        CheckboxComponent,
        ToastComponent,
        ToastManagerComponent,

        // Directives

        // Pipes
        CurrencyPipe,
        DatetimePipe,
    ],
    entryComponents: [],
    providers: []
})
export class SharedModule {
}
