import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-confirm-box',
    templateUrl: './confirm-box.component.html',
    styleUrls: ['./confirm-box.component.scss']
})
export class ConfirmBoxComponent implements OnInit {

    @Input() submitBtnText: string = 'Yes';
    @Input() cancelBtnText: string = 'No';
    @Input() title: string = 'Are you sure?';
    @Input() isShow = false;
    @Input() isActionBtnLoading: boolean = false;

    @Output() onCancel = new EventEmitter<void>();
    @Output() onSubmit = new EventEmitter<void>();

    constructor() {
    }

    ngOnInit(): void {
    }

    emmitCancelClick() {
        this.onCancel.emit();
    }

    emmitSubmitClick() {
        this.onSubmit.emit();
    }
}
