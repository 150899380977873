import {
    ChangeDetectionStrategy,
    Component, EventEmitter, Input,
    OnDestroy,
    OnInit, Output,
    ViewEncapsulation
} from '@angular/core';
import {Subscription} from 'rxjs';
import {
    AccountService,
    AccountSummary,
    AccountSummaryLicensePlate,
    UpdateLPNModel
} from '../../../../../../services/account.service';
import {ToastService} from '../../../../../../services/toast.service';
import {UserService} from '../../../../../../services/user.service';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";

export interface ActionsTabDataUpdateEvent {
    summaryData: AccountSummary;
    lastPIN: string;
}

export function isDuplicateLPnValidator(existsLPNsList: AccountSummaryLicensePlate[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const {lpn, lps} = control.value;
        if (existsLPNsList && existsLPNsList.length > 0) {
            const isLpnExists = existsLPNsList.find(p => p.lpn === lpn);
            const isLpsExists = existsLPNsList.find(p => p.lps === lps);
            const isLicensePlateExists = isLpnExists && isLpsExists;
            return isLicensePlateExists ? {'lpnExists': {lpn}} : null;
        }
        return null;
    };
}

@Component({
    selector: 'account-editor-actions',
    templateUrl: './account-editor-actions.component.html',
    styleUrls: ['./account-editor-actions.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None,
    host: {
        'class': 'account-editor-actions',
    }
})
export class AccountEditorActionsComponent implements OnInit, OnDestroy {
    subs: Subscription[] = [];

    @Input()
    accountId: string;

    summaryData: AccountSummary;

    isDataSet: boolean = false;
    public isLicensePlateUpdatePopupVisible: boolean = false;

    @Input()
    set data(data: ActionsTabDataUpdateEvent) {
        if (!this.isDataSet) {
            this.summaryData = data.summaryData;
            this.lastPIN = data.lastPIN;
            this.isDataSet = true;
        }
    }

    @Output()
    dataChange = new EventEmitter<ActionsTabDataUpdateEvent>();

    isReinstatePopupVisible: boolean = false;

    isReinstating: boolean = false;

    canSendPIN: boolean = false;

    canSendSMS: boolean = false;

    canCloseAccount: boolean = false;

    lastPIN: string = null;

    isSMSPopupVisible: boolean = false;

    isSendingSMS: boolean = false;

    smsText: string = '';

    isSMSTextValid: boolean = false;

    isPINPopupVisible: boolean = false;

    isSendingPIN: boolean = false;

    isCloseAccountPopupVisible: boolean = false;

    isClosingAccount: boolean = false;

    isBlockAccountPopupVisible: boolean = false;

    isBlockingAccount: boolean = false;

    reinstateReason: string = '';

    isReinstateReasonValid: boolean = false;

    isPhoneNumberUpdatePopupVisible: boolean = false;
    isUpdatePhone: boolean = false;
    newPhoneNumber: string = '';
    isNewPhoneNumberValid: boolean = false;

    phoneNumberFormControl = new FormControl('', [Validators.required, Validators.pattern(/^\+?[0-9]{10,11}$/)]);
    licensePLateFormControl = new FormControl('', [Validators.required]);
    listOfLps = [
        "AL", "AK", "AS", "AZ", "AR", "CA", "CO",
        "CT", "DE", "DC", "FM", "FL", "GA", "GU", "HI", "ID", "IL", "IN",
        "IA", "KS", "KY", "LA", "ME", "MH", "MD", "MA", "MI", "MN", "MS",
        "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "MP",
        "OH", "OK", "OR", "PW", "PA", "PR", "RI", "SC", "SD", "TN", "TX",
        "UT", "VT", "VI", "VA", "WA", "WV", "WI", "WY", "AE", "AP", "AA"
    ];


    selectLPNFormGroup: FormGroup = new FormGroup(
        {
            lpn: new FormControl(''),
        }
    );

    updateLPNFormGroup: FormGroup = null;
    canUpdatePhoneNumber: boolean = false;
    canUpdateLicensePlates: boolean = false;
    isOperationInProgress: boolean = false;
    activeLPNsList: AccountSummaryLicensePlate[] | null = null;

    constructor(
        private accountService: AccountService,
        private toastService: ToastService,
        private userService: UserService,
    ) {
        this.canSendPIN = this.userService.checkPermission('ACCOUNT_ACTIONS_SEND_PIN');
        this.canSendSMS = this.userService.checkPermission('ACCOUNT_ACTIONS_SEND_SMS');
        this.canCloseAccount = this.userService.checkPermission('ACCOUNT_CLOSE');
        this.canUpdateLicensePlates = this.userService.checkPermission('ACCOUNT_UPDATE_LICENSE_PLATE');
        this.isUpdatePhone = this.userService.checkPermission('ACCOUNT_UPDATE_PHONE');
    }

    ngOnInit() {

        this.activeLPNsList = this.summaryData.plates ? this.filterLPNsListByStatus(this.summaryData.plates, 'ACTIVE') : null;

        this.updateLPNFormGroup = new FormGroup({
            lps: new FormControl(''),
            lpn: new FormControl('', Validators.compose(
                [
                    Validators.required,
                    Validators.pattern(/^([a-zA-Z0-9]+)$/),
                    Validators.maxLength(8),
                ])
            ),
        }, isDuplicateLPnValidator(this.summaryData.plates));
    }

    ngOnDestroy(): void {
        this.subs.forEach(sub => sub.unsubscribe());
    }

    canReinstateAccount(): boolean {
        return this.isAccountLocked();
    }

    isFleetAccount(): boolean {
        return true;
    }

    isAccountLocked(): boolean {
        return ['SOFT_LOCK', 'HARD_LOCK', 'SUSPICIOUS_ACTIVITY'].includes(this.summaryData.account_status);
    }

    isCloseAccountVisible(): boolean {
        const isClosed = ['PERMANENTLY_CLOSED', 'CLOSED_BY_OWNER'].includes(this.summaryData.account_status);
        return this.canCloseAccount && !isClosed;
    }

    hasActions(): boolean {
        return (this.isAccountLocked() || this.canSendPIN || this.canSendSMS || this.canCloseAccount);
    }

    onReinstateAccount() {
        if (!this.canReinstateAccount() || this.isReinstatePopupVisible) {
            return;
        }

        this.isReinstatePopupVisible = true;
    }

    isReinstateReasonRequired(): boolean {
        // this.summaryData.outstanding_disputes_amount = 1;
        return (
            this.isAccountLocked() &&
            this.isFleetAccount() &&
            this.summaryData.account_status === 'SUSPICIOUS_ACTIVITY' &&
            this.summaryData.outstanding_disputes_amount > 0
        );
    }

    async onConfirmReinstate(isOk: boolean) {
        if (this.isReinstating || isOk && !this.canSubmitReinstate()) {
            return;
        }

        if (!isOk) {
            this.isReinstatePopupVisible = false;
            return;
        }

        this.isReinstating = true;

        const reason = this.isReinstateReasonRequired() ? (this.reinstateReason || '').trim() : '';
        const isReinstated = await this.accountService.reinstateAccount(this.accountId, reason).toPromise().catch(() => false);

        if (isReinstated) {
            this.summaryData = await this.accountService.fetchSummary(this.accountId).toPromise().catch(() => null);
            this.notifyDataUpdate();
        }

        this.isReinstatePopupVisible = false;
        this.isReinstating = false;

        this.toastService.create({
            message: [isReinstated ? 'accounts.actions.reinstate_success' : 'accounts.actions.reinstate_failed'],
            timeout: 5000
        });
    }

    onSendSMS() {
        if (!this.canSendSMS) {
            return;
        }

        this.isSMSPopupVisible = true;
    }

    onChangeSMSText() {
        this.isSMSTextValid = (this.smsText || '').trim().length >= 3;
    }

    async onConfirmSendSMS(send: boolean) {
        if (this.isSendingSMS) {
            return;
        }

        if (!send) {
            this.hideSMSPopup();
            return;
        }

        this.isSendingSMS = true;

        const text = (this.smsText || '').trim();
        const isSent = await this.accountService.sendSMS(this.accountId, text).toPromise().catch(() => false);

        if (isSent) {
            this.hideSMSPopup();
        }

        this.isSendingSMS = false;

        this.toastService.create({
            message: [isSent ? 'accounts.actions.send_sms_success' : 'accounts.actions.send_sms_failed'],
            timeout: 5000
        });
    }

    hideSMSPopup() {
        this.isSMSPopupVisible = false;
        this.smsText = '';
        this.isSMSTextValid = false;
    }

    onConfirmIdentity() {
        if (!this.canSendPIN) {
            return;
        }

        this.isPINPopupVisible = true;
    }

    async onConfirmSendPIN(send: boolean) {
        if (this.isSendingPIN) {
            return;
        }

        if (!send) {
            this.isPINPopupVisible = false;
            return;
        }

        this.isSendingPIN = true;

        this.lastPIN = await this.accountService.sendPIN(this.accountId).toPromise().catch(() => null);
        // this.lastPIN = '1234';

        this.isPINPopupVisible = false;
        this.isSendingPIN = false;

        this.notifyDataUpdate();

        this.toastService.create({
            message: [!!this.lastPIN ? 'accounts.actions.confirm_identity_success' : 'accounts.actions.confirm_identity_failed'],
            timeout: 5000
        });
    }

    notifyDataUpdate() {
        this.dataChange.emit({
            lastPIN: this.lastPIN,
            summaryData: this.summaryData
        });
    }

    onCloseAccount() {
        if (!this.canCloseAccount) {
            return;
        }

        this.isCloseAccountPopupVisible = true;
    }

    async onConfirmCloseAccount(isConfirmed: boolean) {
        if (this.isClosingAccount) {
            return;
        }

        if (!isConfirmed) {
            this.isCloseAccountPopupVisible = false;
            return;
        }

        this.isClosingAccount = true;

        const isClosed = await this.accountService.closeAccount(this.accountId).toPromise().catch(() => false);

        if (isClosed) {
            this.summaryData = await this.accountService.fetchSummary(this.accountId).toPromise().catch(() => null);
            this.notifyDataUpdate();
        }

        this.isCloseAccountPopupVisible = false;
        this.isClosingAccount = false;

        this.toastService.create({
            message: [isClosed ? 'accounts.actions.close_account_success' : 'accounts.actions.close_account_failed'],
            timeout: 5000
        });
    }

    onBlockAccount() {
        if (this.isAccountLocked() || !this.isFleetAccount()) {
            return;
        }

        this.isBlockAccountPopupVisible = true;
    }

    async onConfirmBlockAccount(isConfirmed: boolean) {
        if (this.isBlockingAccount) {
            return;
        }

        if (!isConfirmed) {
            this.isBlockAccountPopupVisible = false;
            return;
        }

        this.isBlockingAccount = true;

        const isBlocked = await this.accountService.blockAccount(this.accountId).toPromise().catch(() => false);

        if (isBlocked) {
            this.summaryData = await this.accountService.fetchSummary(this.accountId).toPromise().catch(() => null);
            this.notifyDataUpdate();
        }

        this.isBlockAccountPopupVisible = false;
        this.isBlockingAccount = false;

        this.toastService.create({
            message: [isBlocked ? 'accounts.actions.block_account_success' : 'accounts.actions.block_account_failed'],
            timeout: 5000
        });
    }

    onChangeReinstateReason() {
        this.isReinstateReasonValid = (this.reinstateReason || '').trim().length >= 3;
    }

    canSubmitReinstate(): boolean {
        return !this.isReinstateReasonRequired() || this.isReinstateReasonValid;
    }

    public openPhoneNumberUpdatePopup() {
        this.isPhoneNumberUpdatePopupVisible = true;
    }

    public onUpdatePhoneNumber() {
        this.isOperationInProgress = true;
        this.accountService.updatePhoneNumber(this.accountId, this.phoneNumberFormControl.value).subscribe((response) => {
            this.isOperationInProgress = false;
            this.showMessage('accounts.actions.update_phone_number.success');
            this.onClosePhoneNumberEditPopup();
            this.reloadPage()
        }, error => {
            this.showMessage('accounts.actions.update_phone_number.failed');
            this.isOperationInProgress = false;
        });
    }

    private showMessage(messageKey: string = '') {
        this.toastService.create({
            message: [messageKey],
            timeout: 700
        });
    }

    onClosePhoneNumberEditPopup() {
        this.isPhoneNumberUpdatePopupVisible = false;
        this.phoneNumberFormControl.reset();
    }

    onCloseLicensePlateEditPopup() {
        this.isLicensePlateUpdatePopupVisible = false;
        this.updateLPNFormGroup.reset();
        this.selectLPNFormGroup.reset();
    }

    private reloadPage(): void {
        window.location.reload();
    }

    onUpdateLicensePlate() {
        this.isOperationInProgress = true;
        const updatedLPNId = this.selectLPNFormGroup.value.lpn;
        const {lps, lpn} = this.updateLPNFormGroup.value;
        const updateLPN: UpdateLPNModel = {
            lps,
            lpn,
            lpc: null,
        }
        this.accountService.updateLicensePlate(this.accountId, updatedLPNId, updateLPN).subscribe((response) => {
            this.showMessage('accounts.actions.update_lpn.success');
            this.isOperationInProgress = false;
            this.onCloseLicensePlateEditPopup();
            this.reloadPage()
        }, error => {
            this.showMessage('accounts.actions.update_lpn.failed');
            this.isOperationInProgress = false;
        });

    }

    openLicensePlateUpdatePopup() {
        if (this.activeLPNsList && this.activeLPNsList.length > 0) {
            const firstLPNOfListID = this.activeLPNsList[0].id;
            this.selectLPNFormGroup.patchValue({'lpn': firstLPNOfListID});
            this.patchUpdateFormControlValue(firstLPNOfListID);
        }

        this.isLicensePlateUpdatePopupVisible = true;
    }


    public patchUpdateFormControlValue(id: string) {
        const {lps, lpn} = this.getLPNFromListById(id);
        this.updateLPNFormGroup.patchValue({'lps': lps});
        this.updateLPNFormGroup.patchValue({'lpn': lpn});
    }

    private getLPNFromListById(id: string): AccountSummaryLicensePlate | undefined {
        return this.summaryData.plates.find(p => p.id === id);
    }

    filterLPNsListByStatus(list: AccountSummaryLicensePlate[], status: string = 'ACTIVE'): AccountSummaryLicensePlate[] | undefined {
        return list.filter(p => p.status === status);
    }

}
