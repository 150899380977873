<ng-container *ngIf="isShow">
    <div class="confirm-box">
        <div class="confirm-box__title">
            <span>{{title}}</span>
        </div>
        <div class="confirm-box__actions">
            <button class="button button_regular button_light-blue mr-16" [disabled]="isActionBtnLoading"
                    (click)="emmitCancelClick()">{{cancelBtnText}}</button>
            <button class="button button_regular button_submit button_blue" [disabled]="isActionBtnLoading"
                    (click)="emmitSubmitClick()">
                <ng-container *ngIf="!isActionBtnLoading">
                    {{submitBtnText}}</ng-container>
                <ng-container *ngIf="isActionBtnLoading">
                    loading...
                </ng-container>
            </button>
        </div>
    </div>
</ng-container>
