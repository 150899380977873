import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-no-items-message',
    templateUrl: './no-items-message.component.html',
    styleUrls: ['./no-items-message.component.scss']
})
export class NoItemsMessageComponent implements OnInit {
    @Input() messageTranslateKey: string = 'No items!';
    @Input() isShow: boolean = false;

    constructor() {
    }

    ngOnInit(): void {
    }

}
