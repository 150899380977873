import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActionsTabDataUpdateEvent} from "../../account-editor-actions/account-editor-actions.component";

export interface NotesHttpResponse {
    notes: Note[];
}

export interface Note {
    email: string;
    inserted: Date;
    note: string;
}

@Component({
    selector: 'app-account-editor-notes',
    templateUrl: './account-editor-notes.component.html',
    styleUrls: ['./account-editor-notes.component.scss']
})
export class AccountEditorNotesComponent implements OnInit {

    @Input() data: NotesHttpResponse;
    @Input() accountId: string;
    @Output()
    dataChange = new EventEmitter<ActionsTabDataUpdateEvent>();
    listOfTableCols: { field: string, translateKey: string }[] = [
        {field: 'inserted', translateKey: 'accounts.notes.table.date'},
        {field: 'email', translateKey: 'accounts.notes.table.email'},
        {field: 'note', translateKey: 'accounts.notes.table.note'},
    ];


    constructor() {
    }

    ngOnInit(): void {
    }

}
