import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PopupComponent} from "./popup.component";
import {PopupHeaderComponent} from "./popup-header.component";
import {PopupContentComponent} from "./popup-content.component";
import {PopupControlsComponent} from "./popup-controls.component";
import {ConfirmBoxComponent} from "./confirm-box/confirm-box.component";
import {TranslateModule} from "@ngx-translate/core";


@NgModule({
    declarations: [PopupComponent,
        PopupHeaderComponent,
        PopupContentComponent,
        PopupControlsComponent,
        ConfirmBoxComponent,],
    exports: [
        PopupComponent,
        PopupControlsComponent,
        PopupHeaderComponent,
        PopupContentComponent
    ],
    imports: [
        CommonModule,
        TranslateModule
    ]
})
export class PopupModule {
}
