import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoItemsMessageComponent } from './no-items-message/no-items-message.component';
import {TranslateModule} from "@ngx-translate/core";



@NgModule({
    declarations: [NoItemsMessageComponent],
    exports: [
        NoItemsMessageComponent
    ],
    imports: [
        CommonModule,
        TranslateModule
    ]
})
export class NoItemsMessageModule { }
