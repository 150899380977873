<div *ngIf="!data.notes">
    <loader></loader>
</div>

<app-no-items-message messageTranslateKey="accounts.notes.no_notes"
                      [isShow]="data.notes && data.notes.length < 1"></app-no-items-message>
<div class="table table_middle table_striped table--notes"
     *ngIf="data.notes && data.notes.length > 0">
    <div class="table__header">
        <div class="table__row">
            <div class="table__cell" *ngFor="let col of listOfTableCols">
                <span>{{col.translateKey | translate}}</span>
            </div>
        </div>
    </div>
    <div class="table__body">
        <div class="table__row" *ngFor="let i of data.notes">
            <div class="table__cell">
                <span>{{i.inserted | datetime}}</span>
            </div>
            <div class="table__cell">
                <span>{{i.email}}</span>
            </div>
            <div class="table__cell">
                <span>{{i.note}}</span>
            </div>
        </div>
    </div>
</div>
