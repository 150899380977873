import {
    ChangeDetectionStrategy,
    Component, EventEmitter, Input, OnChanges,
    OnDestroy,
    OnInit, Output,
    ViewEncapsulation
} from '@angular/core';
import {Subscription} from 'rxjs';
import {
    AccountService,
    ExtendInvoiceDateRequestData,
    OutstandingInvoice,
    OutstandingInvoiceWithExtension, TransactionResponse
} from '../../../../../../services/account.service';
import {DomService} from '../../../../../../services/dom.service';
import {ToastService} from '../../../../../../services/toast.service';
import {Permission, UserService} from "../../../../../../services/user.service";


@Component({
    selector: 'account-editor-invoices',
    templateUrl: './account-editor-invoices.component.html',
    styleUrls: ['./account-editor-invoices.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        'class': 'account-editor-invoices',
    }
})
export class AccountEditorInvoicesComponent implements OnInit, OnDestroy {
    subs: Subscription[] = [];

    listOfInvoices: OutstandingInvoiceWithExtension[];

    invoicesToInspect: OutstandingInvoiceWithExtension[] = null;

    @Input()
    accountId: string;

    shouldBeSaved: boolean = false;

    isSaving: boolean = false;

    isDataSet: boolean = false;
    private selectedInvoice: OutstandingInvoice;

    @Input()
    set data(invoices: OutstandingInvoiceWithExtension[]) {
        if (!this.isDataSet) {
            this.listOfInvoices = invoices;
            this.isDataSet = true;
        }
    }

    @Output()
    dataChange = new EventEmitter<OutstandingInvoiceWithExtension[]>();

    extendHoursOptions: number[] = [null, 24, 48, 72];
    isConfirmBoxActionBtnLoading: boolean = false;

    public isHasPermissionForCancelOutstandingInvoices: boolean = false;

    constructor(
        private accountService: AccountService,
        private toastService: ToastService,
        private domService: DomService,
        private userService: UserService
    ) {
    }

    ngOnInit() {
        this.isHasPermissionForCancelOutstandingInvoices = this.checkPermissionForAction('ACCOUNT_CANCEL_OUTSTANDING_INVOICES');
    }

    private checkPermissionForAction(action_name: Permission): boolean {
        return this.userService.checkPermission(action_name);
    }

    ngOnDestroy(): void {
        this.subs.forEach(sub => sub.unsubscribe());
    }

    onCloseInvoicePopup() {
        this.invoicesToInspect = null;
    }

    onInvoiceClick(invoice: OutstandingInvoiceWithExtension, e: any) {
        if (this.domService.hasEventMark(e, 'extCellClick')) {
            return;
        }

        this.selectedInvoice = invoice;

        this.invoicesToInspect = [invoice];
    }

    onExtendCellClick(e: any) {
        this.domService.markEvent(e, 'extCellClick');
    }

    onCheckExtensionHours() {
        this.shouldBeSaved = this.listOfInvoices.some(invoice => invoice.hours && invoice.hours > 0);
    }

    async onSave() {
        if (this.isSaving) {
            return;
        }

        const invoicesToSave: ExtendInvoiceDateRequestData[] = this.listOfInvoices.reduce((acc, invoice) => {
            if (invoice.hours && invoice.hours > 0) {
                acc.push({
                    invoice_id: invoice.invoice_id,
                    hours: invoice.hours
                });
            }
            return acc;
        }, []);

        if (invoicesToSave.length <= 0) {
            return;
        }

        this.isSaving = true;

        const isExtended = await Promise.all(
            invoicesToSave.map(invoice => this.accountService.saveInvoiceExtension(this.accountId, invoice).toPromise())
        ).catch(() => false);

        await this.getListOfInvoices();
        this.onCheckExtensionHours();
        this.notifyDataUpdate();

        this.isSaving = false;

        if (isExtended) {
            this.toastService.create({
                message: ['accounts.invoices.extend_success'],
                timeout: 5000
            });
        } else {
            this.toastService.create({
                message: ['accounts.invoices.extend_error'],
                timeout: 5000
            });
        }
    }

    private async getListOfInvoices() {
        this.listOfInvoices = await this.accountService.fetchExtendedInvoices(this.accountId).catch(() => null);
    }

    notifyDataUpdate() {
        this.dataChange.emit(this.listOfInvoices);
    }

    cancelInvoice(_$event: string) {
        this.accountService.cancelInvoice(this.accountId, this.selectedInvoice.invoice_id).subscribe(
            (response: TransactionResponse) => {
                this.invoicesToInspect = null;
                this.toastService.create({
                    message: [`Invoice ${this.selectedInvoice.invoice_name} cancelled successfully`],
                    timeout: 5000
                });
                this.isConfirmBoxActionBtnLoading = false;
                this.getListOfInvoices().then(r => this.notifyDataUpdate());
            }, error => {
                this.toastService.create({
                    message: [`Error cancelling invoice ${this.selectedInvoice.invoice_id}.`],
                    timeout: 5000
                });
                this.isConfirmBoxActionBtnLoading = false;
                console.error(error);
            }
        );
    }
}
