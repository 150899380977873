// Do not use modern ES6 modules syntax because this file is used by webpack config

// NOTE: locale.code must be in moment.js locale format
// Format: https://momentjs.com/docs/#/displaying/format/
// Date format by country: https://en.wikipedia.org/wiki/Date_format_by_country
// Time format by country: https://i.imgur.com/5nTbinL.png
const LOCALES = [
    {
        code: 'en',
        name: 'English',
        isDefault: true,
        format: {
            input: {
                date: 'MM/DD/YY',
                time: 'h:mm A',
                datetime: 'MM/DD/YY h:mm A',
            },
            display: {
                date: 'MM/DD/YY',
                time: 'h:mm A',
                datetime: 'MM/DD/YY h:mm A',
            }
        }
    },
];

exports.LOCALES = LOCALES;
