import {
    ChangeDetectionStrategy,
    Component, EventEmitter, Input, OnChanges,
    OnDestroy,
    OnInit, Output, SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import {Subscription} from 'rxjs';
import {AccountService, OutstandingInvoice, Transaction} from '../../../../../../services/account.service';

@Component({
    selector: 'account-editor-invoice-details',
    templateUrl: './account-editor-invoice-details.component.html',
    styleUrls: ['./account-editor-invoice-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        'class': 'account-editor-invoice-details',
    }
})
export class AccountEditorInvoiceDetailsComponent implements OnInit, OnDestroy {
    subs: Subscription[] = [];

    @Input()
    popupTitle: string = null;

    @Input() transaction: Transaction = null;

    @Input() isShowRefundButton = false;
    @Input() isShowInvoiceCancelButton = false;

    @Input()
    accountId: string = null;

    @Input()
    invoices: OutstandingInvoice[] = null;

    @Input()
    invoicesToLoad: string[] = null;

    @Output()
    onClose = new EventEmitter<void>();

    @Output() onRefund = new EventEmitter<string>();
    @Output() onCancelInvoice = new EventEmitter<string>();
    @Output() onCancelCancelingInvoice = new EventEmitter<string>();

    isShowConfirmBox = false;
    @Input() isConfirmBoxActionBtnLoading: boolean = false;

    constructor(
        private accountService: AccountService
    ) {
    }

    ngOnInit() {
        this.init();
    }

    ngOnDestroy(): void {
        this.subs.forEach(sub => sub.unsubscribe());
    }

    async init() {
        if (this.invoicesToLoad) {
            await this.fetchInvoices();
        }
    }

    async fetchInvoices() {
        Promise.all(
            this.invoicesToLoad.map(invoiceId => {
                return this.accountService.fetchInvoiceDetails(this.accountId, invoiceId).toPromise()
            })
        ).then((invoices: OutstandingInvoice[]) => {
            this.invoices = invoices;
        });
    }

    refundTransaction() {
        this.onRefund.emit('refund');
    }

    showConfirmBox() {
        this.isShowConfirmBox = true;
    }

    hideConfirmBox() {
        this.isShowConfirmBox = false;
    }

    cancelInvoice() {
        this.isConfirmBoxActionBtnLoading = true;
        this.onCancelInvoice.emit('cancel-invoice');
    }

    cancelCancelingInvoice() {
        this.hideConfirmBox();
        this.onCancelCancelingInvoice.emit('cancel-canceling-invoice');
    }
}
