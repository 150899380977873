<ng-container [ngSwitch]="hasActions()">
    <div class="account-editor-actions__message" *ngSwitchCase="false">
        {{ 'accounts.actions.no_actions' | translate }}
    </div>
    <div class="account-editor-actions__items" *ngSwitchCase="true">
        <div class="account-editor-actions__item" *ngIf="canReinstateAccount()" (click)="onReinstateAccount()">
            <span
                class="account-editor-actions__item-header account-editor-actions__item-header_green">{{ 'accounts.actions.reinstate_account' | translate }}</span>
            <span class="account-editor-actions__item-text"
                  [innerHTML]="'accounts.actions.reinstate_desc' | translate : { phone: summaryData.phone_number, lockDate: (summaryData.lock_date | datetime : 'display.datetime') }"></span>
        </div>
        <div class="account-editor-actions__item" *ngIf="canSendSMS" (click)="onSendSMS()">
            <span class="account-editor-actions__item-header">{{ 'accounts.actions.send_sms' | translate }}</span>
            <span class="account-editor-actions__item-text"
                  [innerHTML]="'accounts.actions.send_sms_desc' | translate : { phone: summaryData.phone_number }"></span>
        </div>
        <div class="account-editor-actions__item" *ngIf="canSendPIN" (click)="onConfirmIdentity()">
            <span
                class="account-editor-actions__item-header">{{ 'accounts.actions.confirm_identity' | translate }}</span>
            <span class="account-editor-actions__item-text" [ngSwitch]="!!lastPIN">
                <span *ngSwitchCase="false" [innerHTML]="'accounts.actions.confirm_identity_desc' | translate"></span>
                <span *ngSwitchCase="true"
                      [innerHTML]="'accounts.actions.confirm_identity_desc_with_pin' | translate : { pin: lastPIN }"></span>
            </span>
        </div>

        <div class="account-editor-actions__item" *ngIf="true"
             (click)="openPhoneNumberUpdatePopup()">
            <span
                class="account-editor-actions__item-header">{{'accounts.actions.update_phone_number.card_title' | translate}}</span>
            <span class="account-editor-actions__item-text"
                  [innerHTML]="'accounts.actions.update_phone_number.card_desc' | translate"></span>
        </div>

        <div class="account-editor-actions__item" *ngIf="canUpdateLicensePlates"
             (click)="openLicensePlateUpdatePopup()">
            <span
                class="account-editor-actions__item-header">{{'accounts.actions.update_lpn.card_title' | translate}}</span>
            <span class="account-editor-actions__item-text"
                  [innerHTML]="'accounts.actions.update_lpn.card_desc' | translate"></span>
        </div>


        <div class="account-editor-actions__item" *ngIf="!isAccountLocked() && isFleetAccount()"
             (click)="onBlockAccount()">
            <span
                class="account-editor-actions__item-header account-editor-actions__item-header_red">{{ 'accounts.actions.block_account' | translate }}</span>
            <span class="account-editor-actions__item-text"
                  [innerHTML]="'accounts.actions.block_desc' | translate"></span>
        </div>
        <div class="account-editor-actions__item" *ngIf="isCloseAccountVisible()" (click)="onCloseAccount()">
            <span class="account-editor-actions__item-header account-editor-actions__item-header_red"
                  [innerHTML]="'accounts.actions.close_account' | translate"></span>
            <span class="account-editor-actions__item-text"
                  [innerHTML]="'accounts.actions.close_account_desc' | translate"></span>
        </div>
    </div>
</ng-container>

<popup-custom class="account-editor-actions__reinstate-popup" [isDisabled]="isReinstating"
              *ngIf="isReinstatePopupVisible">
    <popup-content [ngSwitch]="isReinstateReasonRequired()">
        <ng-container *ngSwitchCase="false">
            {{ 'accounts.actions.reinstate_confirm' | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="true">
            {{ 'accounts.actions.reinstate_message' | translate }}
            <textarea class="textarea account-editor-actions__reinstate-popup-text"
                      [(ngModel)]="reinstateReason"
                      (ngModelChange)="onChangeReinstateReason()"
            ></textarea>
        </ng-container>
    </popup-content>
    <popup-controls [isStretched]="true">
        <button type="button" class="button button_regular button_light-blue" (click)="onConfirmReinstate(false)">
            <span class="button__caption">{{ 'accounts.actions.reinstate_cancel' | translate }}</span>
        </button>
        <button type="button" class="button button_regular button_blue"
                [isDisabled]="!canSubmitReinstate()"
                [isProgress]="isReinstating"
                (click)="onConfirmReinstate(true)"
        >
            <span class="button__caption">{{ 'accounts.actions.reinstate_ok' | translate }}</span>
        </button>
    </popup-controls>
</popup-custom>

<popup-custom class="account-editor-actions__sms-popup" [isDisabled]="isSendingSMS" *ngIf="isSMSPopupVisible">
    <popup-content>
        <label class="account-editor-actions__sms-popup-label">
            <span class="account-editor-actions__sms-popup-label-text">
                {{ 'accounts.actions.send_sms_label' | translate }}
            </span>
            <textarea class="textarea account-editor-actions__sms-popup-text"
                      [(ngModel)]="smsText"
                      (ngModelChange)="onChangeSMSText()"
            ></textarea>
        </label>
    </popup-content>
    <popup-controls [isStretched]="true">
        <button type="button" class="button button_regular button_light-blue" (click)="onConfirmSendSMS(false)">
            <span class="button__caption">{{ 'accounts.actions.send_sms_cancel' | translate }}</span>
        </button>
        <button type="button" class="button button_regular button_blue"
                [isDisabled]="!isSMSTextValid"
                [isProgress]="isSendingSMS"
                (click)="onConfirmSendSMS(true)"
        >
            <span class="button__caption">{{ 'accounts.actions.send_sms_ok' | translate }}</span>
        </button>
    </popup-controls>
</popup-custom>

<popup-custom class="account-editor-actions__pin-popup" [isDisabled]="isSendingPIN" *ngIf="isPINPopupVisible">
    <popup-content>
        <span
            [innerHTML]="'accounts.actions.confirm_identity_confirm' | translate : { phone: summaryData.phone_number }"></span>
    </popup-content>
    <popup-controls [isStretched]="true">
        <button type="button" class="button button_regular button_light-blue" (click)="onConfirmSendPIN(false)">
            <span class="button__caption">{{ 'accounts.actions.confirm_identity_cancel' | translate }}</span>
        </button>
        <button type="button" class="button button_regular button_blue"
                [isProgress]="isSendingPIN"
                (click)="onConfirmSendPIN(true)"
        >
            <span class="button__caption">{{ 'accounts.actions.confirm_identity_ok' | translate }}</span>
        </button>
    </popup-controls>
</popup-custom>

<popup-custom class="account-editor-actions__close-account-popup"
              *ngIf="isCloseAccountPopupVisible"
              [isDisabled]="isClosingAccount"
>
    <popup-content>
        {{ 'accounts.actions.close_account_confirm' | translate }}
    </popup-content>
    <popup-controls [isStretched]="true">
        <button type="button" class="button button_regular button_light-red" (click)="onConfirmCloseAccount(false)">
            <span class="button__caption">{{ 'accounts.actions.close_account_cancel' | translate }}</span>
        </button>
        <button type="button" class="button button_regular button_red"
                [isProgress]="isClosingAccount"
                (click)="onConfirmCloseAccount(true)"
        >
            <span class="button__caption">{{ 'accounts.actions.close_account_ok' | translate }}</span>
        </button>
    </popup-controls>
</popup-custom>

<popup-custom class="account-editor-actions__block-account-popup"
              *ngIf="isBlockAccountPopupVisible"
              [isDisabled]="isBlockingAccount"
>
    <popup-content>
        {{ 'accounts.actions.block_account_confirm' | translate }}
    </popup-content>
    <popup-controls [isStretched]="true">
        <button type="button" class="button button_regular button_light-red" (click)="onConfirmBlockAccount(false)">
            <span class="button__caption">{{ 'accounts.actions.block_account_cancel' | translate }}</span>
        </button>
        <button type="button" class="button button_regular button_red"
                [isProgress]="isBlockingAccount"
                (click)="onConfirmBlockAccount(true)"
        >
            <span class="button__caption">{{ 'accounts.actions.block_account_ok' | translate }}</span>
        </button>
    </popup-controls>
</popup-custom>

<popup-custom class="change-phone-popup" [isDisabled]="isOperationInProgress"
              *ngIf="isPhoneNumberUpdatePopupVisible">
    <popup-content class="change-phone-popup-content">
        <h3 class="change-phone-title">{{'accounts.actions.update_phone_number.title' | translate}}</h3>
        <span class="old-phone-label">{{ 'accounts.actions.update_phone_number.label_old' | translate }}: <span
            class="old-phone-label__phone">{{summaryData.phone_number}}</span></span>
        <label class="account-editor-actions__phone-edit-label">
            <span class="account-editor-actions__sms-popup-label-text d-inline">
                {{ 'accounts.actions.update_phone_number.label_new' | translate }}
            </span>
            <input type="tel" name="phone"
                   required class="input input_regular update-phone-input"
                   [formControl]="phoneNumberFormControl"
            >
        </label>
        <span class="error-message"
              *ngIf="phoneNumberFormControl.touched && phoneNumberFormControl.dirty && phoneNumberFormControl.hasError('required')">
                {{ 'accounts.actions.update_phone_number.error_required' | translate }}</span>
        <span class="error-message"
              *ngIf="phoneNumberFormControl.touched && phoneNumberFormControl.dirty && phoneNumberFormControl.hasError('pattern')">
                {{ 'accounts.actions.update_phone_number.error_pattern' | translate }}</span>
    </popup-content>
    <popup-controls [isStretched]="true">
        <button type="button" class="button button_regular button_light-blue" (click)="onClosePhoneNumberEditPopup()">
            <span class="button__caption">{{ 'accounts.actions.update_phone_number.cancel' | translate }}</span>
        </button>
        <button type="button" class="button button_regular button_blue"
                [isDisabled]="phoneNumberFormControl.invalid"
                [isProgress]="isOperationInProgress"
                (click)="onUpdatePhoneNumber()"
        >
            <span class="button__caption">{{ 'accounts.actions.update_phone_number.ok' | translate }}</span>
        </button>
    </popup-controls>
</popup-custom>

<popup-custom class="change-phone-popup" [isDisabled]="isOperationInProgress"
              *ngIf="isLicensePlateUpdatePopupVisible">
    <popup-content class="change-phone-popup-content">
        <h3 class="change-phone-title">{{'accounts.actions.update_lpn.title' | translate}}</h3>
        <div class="lpn-list-header">
            <div class="lpn-list-header__radio-col"></div>
            <div
                class="lpn-list-header__lps-col">{{'accounts.actions.update_lpn.lpn_list_header_lps' | translate}}</div>
            <div
                class="lpn-list-header__lpn-col">{{'accounts.actions.update_lpn.lpn_list_header_lpn' | translate}}</div>
        </div>
        <div class="edit-lpn-select-wrp" [formGroup]="selectLPNFormGroup">
            <ng-container *ngFor="let item of activeLPNsList">
                <div class="edit-lpn-select">
                    <input class="edit-lpn-select__control" formControlName="lpn"
                           (ngModelChange)="patchUpdateFormControlValue(item.id)"
                           [id]="item.id"
                           [value]="item.id" type="radio" name="lpn">
                    <label class="edit-lpn-select__label" [for]="item.id">
                        <span class="label-lpn-lps">{{item.lps}}</span> <span class="label-lpn-lpn">{{item.lpn}}</span></label>
                </div>
            </ng-container>
        </div>
        <div [formGroup]="updateLPNFormGroup">
            <span class="update-lpn-input-label">{{'accounts.actions.update_lpn.label_new' | translate}}</span>
            <div class="update-lpn-input">
                <select class="update-lpn-input__lps-control input input_regular" formControlName="lps">
                    <option *ngFor="let lps of listOfLps" [value]="lps">{{lps}}</option>
                </select>
                <div>
                    <input
                        formControlName="lpn" class="update-lpn-input__lpn-control input input_regular"
                        type="text">
                    <span class="validation-error" *ngIf="updateLPNFormGroup.get('lpn').hasError('required')">
                        {{ 'accounts.actions.update_lpn.error_required' | translate }}</span>
                    <span class="validation-error" *ngIf="updateLPNFormGroup.get('lpn').hasError('pattern')">
                        {{ 'accounts.actions.update_lpn.error_pattern' | translate }}</span>
                    <span class="validation-error" *ngIf="updateLPNFormGroup.get('lpn').hasError('maxlength')">
                        {{ 'accounts.actions.update_lpn.error_max_length' | translate: {maxLength: 8}  }}</span>
                    <span class="validation-error" *ngIf="updateLPNFormGroup.hasError('lpnExists')">
                        {{ 'accounts.actions.update_lpn.error_duplicate_lpn' | translate }}</span>
                </div>
            </div>
        </div>
    </popup-content>
    <popup-controls [isStretched]="true">
        <button type="button" class="button button_regular button_light-blue" (click)="onCloseLicensePlateEditPopup()">
            <span class="button__caption">{{'accounts.actions.update_lpn.cancel' | translate}}</span>
        </button>
        <button type="button" class="button button_regular button_blue"
                [isDisabled]="updateLPNFormGroup.get('lpn').invalid"
                [isProgress]="isOperationInProgress"
                (click)="onUpdateLicensePlate()"
        >
            <span class="button__caption">{{'accounts.actions.update_lpn.ok' | translate}}</span>
        </button>
    </popup-controls>
</popup-custom>

