import { LOCALES } from './common';

export const CONFIG = {
    isProduction: true,
    isDebug: false,
    env: 'demo',
    locales: LOCALES,
    server: 'https://demo-app.tapnpaydemo.com',
    authBrand: 'demo',
    google: {
        apiKey: 'AIzaSyDSDXVnwnr3PuEQh6DnkYyrvEjF52LHpXs'
    }
};
