import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountEditorNotesComponent } from './account-editor-notes/account-editor-notes.component';
import {SharedModule} from "../../../../../shared.module";
import {NoItemsMessageModule} from "../../../../_widgets/no-items-message/no-items-message.module";
import {LoaderModule} from "../../../../_widgets/loader/loader.module";



@NgModule({
    declarations: [AccountEditorNotesComponent],
    exports: [
        AccountEditorNotesComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        NoItemsMessageModule,
        LoaderModule
    ]
})
export class AccountEditorNotesModule { }
